import { defu } from 'defu';
import type { UseFetchOptions } from 'nuxt/app';

export const useApi: typeof useFetch = <T>(
	url: MaybeRefOrGetter<string>,
	options: UseFetchOptions<T> = {},
) => {
	const { token } = useAuth();

	const defaults: UseFetchOptions<T> = {
		key: toValue(url),
		headers: token.value ? { Authorization: `${token.value}` } : {},
	};

	// for nice deep defaults, please use unjs/defu
	const params = defu(options, defaults);

	return useFetch(url, params);
};
